import Image from 'next/image'
import Link from 'next/link'
import { Flex } from 'theme-ui'
import s from './footer.module.scss'

export const Footer = () => {
  return (
    <footer className={s.footer}>
      <div className={s.footerWrapper}>
        <p className={s.copyright}>
          <Link href="/">&copy; {new Date().getFullYear()} Ride the Bus.</Link>
        </p>
        <Flex>
          <Link
            passHref
            className={s.socialLink}
            href="https://docs.ridethebus.xyz"
            aria-label="Docs"
          >
            <Image
              src="/images/logos/gitbook.svg"
              alt="Docs"
              width={24}
              height={24}
            />
          </Link>
          <Link
            passHref
            className={s.socialLink}
            href="https://paragraph.xyz/@ridethebus"
            aria-label="Paragraph"
          >
            <Image
              src="/images/logos/paragraph.svg"
              alt="Paragraph"
              width={24}
              height={24}
            />
          </Link>
          <Link
            passHref
            className={s.socialLink}
            href="https://warpcast.com/~/channel/ridethebus"
            aria-label="Farcaster"
          >
            <Image
              src="/images/logos/farcaster.svg"
              alt="Farcaster"
              width={24}
              height={24}
            />
          </Link>
          <Link
            passHref
            className={s.socialLink}
            href="https://discord.gg/uJYZFqFuMZ"
            aria-label="Discord"
          >
            <Image
              src="/images/logos/discord2.svg"
              alt="Discord"
              width={24}
              height={24}
            />
          </Link>
          <Link
            passHref
            className={s.twitterLink}
            href="https://twitter.com/0xRideTheBus"
            aria-label="Twitter"
          >
            <Image
              src="/images/logos/twitter-x.svg"
              alt="Twitter"
              width={24}
              height={24}
            />
          </Link>
        </Flex>
      </div>
    </footer>
  )
}
